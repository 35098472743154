import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '@/config';


const state = {
    User: null
}

const getters = {
    user: state => state.User,
}

const actions = {
    async getUser({commit}) {
        try {
            const response = await axios.get(API_URL + '/v1/profile', {
                headers: {
                    Authorization: `Bearer ${Cookies.get('auth_token')}`,
                }
            });
            commit("SET_USER", response.data);
            return response.data;
        } catch (error) {
            throw new Error (error.response.message);
        }
    }
}

const mutations = {
    SET_USER: (state, data) => state.User = data,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
