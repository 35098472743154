<template>
    <h1 class="forgot-password-title">Forgot Password</h1>
    <form class="forgot-container" 
        @submit.prevent="handleSubmit"
        v-show="isShowForgot">
        <div class="form-group mt-5">
            <label for="email" class="email-label">
                Email
            </label>
            <div class="email-container">
                <input 
                    v-model="formData.email"
                    id="email" 
                    type="text" 
                    class="form-control mt-2 email" 
                    placeholder="Enter email address"
                    @keydown.enter.prevent>
                <button @click="getCode" class="get-code">
                    Get Code
                </button>
            </div>
        </div>
        <div class="form-group mt-4">
            <label for="code" class="code-label">
                Code
            </label>
            <input 
                v-model="formData.code"
                id="code" 
                type="text" 
                class="form-control mt-2 code" 
                placeholder="Enter Code">
        </div>
        <div class="error-message" v-if="errors.length">
            <ul class="error-container">
                <li v-for="(error, index) in errors" :key="index">
                    {{ error }}
                </li>
            </ul>
        </div>
        <div class="back-next-container">
            <button class="back"
                @click="backToLogin">
                Back
            </button>
            <button class="next" 
                :disabled="isDisabled"
                @click="nextToPassword"
                :style="{ 
                    backgroundColor: validate ? '#6146DF' : '#B0B0B0' }">
                Next
            </button>
        </div>
    </form>
    <form class="password-container" 
        @submit.prevent="handleSubmit"
        v-show="isShowPassword">
          <div class="form-group mt-5">
            <i  
                @click="toggleNewPasswordEye"
                v-show="isHideNewPasswordEye" 
                class="bi bi-eye-fill new-icon">
            </i>
            <svg 
                @click="toggleNewPasswordEye"
                v-show="isHideNewPasswordEyeClose"
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                class="humbleicons hi-eye-close new-icon">
                <path xmlns="http://www.w3.org/2000/svg" stroke-width="2" :d="icon.EYE_CLOSE"/>
            </svg>
            <label for="newPassword" class="password-label">
                New Password
            </label>
            <input 
                v-model="formData.new_password"
                id="newPassword" 
                :type="newPasswordType" 
                class="form-control mt-2 new-password" 
                placeholder="Enter your password">
        </div>
        <div class="form-group mt-5">
            <i  
                @click="toggleConfirmPasswordEye"
                v-show="isHideConfirmPasswordEye" 
                class="bi bi-eye-fill confirm-icon">
            </i>
            <svg 
                @click="toggleConfirmPasswordEye"
                v-show="isHideConfirmPasswordEyeClose"
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                class="humbleicons hi-eye-close confirm-icon">
                <path xmlns="http://www.w3.org/2000/svg" stroke-width="2" :d="icon.EYE_CLOSE"/>
            </svg>
            <label for="confirmPassword" class="password-label">
                Confirm Password
            </label>
            <input 
                v-model="formData.confirm_password"
                id="confirmPassword" 
                :type="confirmPasswordType" 
                class="form-control mt-2 confirm-password" 
                placeholder="Enter your password">
        </div>
        <div class="error-message" v-if="errors.length">
            <ul class="error-container">
                <li v-for="(error, index) in errors" :key="index">
                    {{ error }}
                </li>
            </ul>
        </div>
        <div class="back-next-container">
            <button class="back"
                @click="backToForgot">
                Back
            </button>
            <button class="next" 

                @click="backToLogin"
                type="submit"
                style="background-color:#6146DF">
                Next
            </button>
        </div>
    </form>
</template>

<script>
import * as icon from '@/assets/js/icon';

export default {
    name: 'ForgotPassword',
    emits: ['backToLogin'],
    data() {
        return {
            icon,
            formData: {
                email: '',
                code: '',
                new_password: '',
                confirm_password: ''
            },
            errors: [],
            isDisabled: true,
            validate: false,
            isHideNewPasswordEyeClose: true,
            isHideNewPasswordEye: false,
            isHideConfirmPasswordEyeClose: true,
            isHideConfirmPasswordEye: false,
            newPasswordType: 'password',
            confirmPasswordType: 'password',
            isShowForgot: true, 
            isShowPassword: false
        }
    },
    methods: {
        getCode() {
            this.errors = [];
            if (!this.formData.email) {
                this.errors.push("Please enter your email address");
            }

            if (this.formData.email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.formData.email)) {
                this.errors.push("Please enter a valid email address");
            }

            this.validate   = true;
            this.isDisabled = false;
            if (this.errors == 0) {
                this.isDisabled = false;
                this.validate   = true;
            }
        },
        nextToPassword() {
            this.errors = [];
            if (!this.formData.code) {
                this.errors.push("Please enter your code");
                return;
            }

            if (this.errors == 0) {
                this.isShowForgot   = false;
                this.isShowPassword = true;
            }

            this.isShowForgot   = false;
            this.isShowPassword = true;
           
        },
        backToForgot() {
            this.isShowForgot   = true;
            this.isShowPassword = false;

            this.errors = [];

        },
        backToLogin() {
            this.$emit('backToLogin');
        },
        toggleNewPasswordEye() {
            this.toggleEyeStates(
                'isHideNewPasswordEye', 'isHideNewPasswordEyeClose', 'newPasswordType'
            );
        },
        toggleConfirmPasswordEye() {
            this.toggleEyeStates(
                'isHideConfirmPasswordEye', 'isHideConfirmPasswordEyeClose', 'confirmPasswordType'
            );
        },
        toggleEyeStates(eyeState, closeEyeState, passwordType) {
            this[eyeState] = !this[eyeState];
            this[closeEyeState] = !this[closeEyeState];
            this[eyeState] ? (this[passwordType] = 'text') : (this[passwordType] = 'password');
        },
        submitBtn() {
            this.errors = [];
            
            if (!this.formData.new_password) {
                this.errors.push("Please enter your new password");
            }

            if (!this.formData.confirm_password) {
                this.errors.push("Please enter your confirm password");
            }

            if (this.errors == 0) {
                alert("SUCESS!");
            }
        }
    }
}
</script>

<style scoped>
    .form-control {
        font-size: 13px;
        padding: 10px;
        box-shadow:  1px 1px 1px #B4B0B1;
        border: 1px solid #B4B0B1;
        outline: 0;
        padding-right: 35px;
        position: relative;
    }
    .form-control:focus {
        border: 1px solid #B4B0B1;
        box-shadow:  1px 1px 1px #B4B0B1;
    }
    .email-label,
    .code-label,
    .password-label {
        font-size: 18px;
        font-weight: 500;
    }
    .email-container {
        display: flex;
        gap: 10px;
    }
    .email {
        flex: 1;
    }
    .get-code {
        background-color: #6146DF;
        color: #FFFFFF;
        border: none;
        border-radius: 10px;
        font-size: 13px;
        margin-top: 8px;
        padding: 5px 10px;
    }
    .back-next-container {
        display: flex;
        gap: 10px;
        margin-top: 80px;
    }
    .back {
        width: 100%;
        border: 1px solid #6146DF;
        color: #6146DF;
        padding: 10px 0px;
        border-radius: 7px;
    }
    .next {
        width: 100%;
        border: none;
        border-radius: 7px;
        color: #FFFFFF;
    }
    .error-message {
        position: absolute;
        margin-top: 5px;
        font-size: 13px;
        color: #FF0000;
    }
    .error-container {
        list-style-type: none;
        padding: 10px 5px;
    }
    .new-icon,
    .confirm-icon {
        position: absolute;
        z-index: 1;
        font-size: 20px;
        color: #B0B0B0;
        cursor: pointer;
    }
    .email,
    .code {
        padding-right: 5px;
    }
    .hi-eye-close {
        width: 25px;
        height: 25px;
        color: #B0B0B5;
    }
    .hi-eye-close.new-icon {
        top: 180px;
        right: 45px;
    }
    .hi-eye-close.confirm-icon {
        top: 305px;
        right: 45px;
    }
    .bi-eye-fill {
        font-size: 20px;
    }
    .bi-eye-fill.new-icon {
        right: 48px;
        top: 178px;
    }
    .bi-eye-fill.confirm-icon {
        right: 48px;
        top: 302px;
    }

    /* responsive screen size */
    @media (width <= 1024px) {
        .forgot-password-title {
            font-size: 80px;
        }
        .email-label,
        .code-label,
        .password-label {
            font-size: 32px;
        }
        .email,
        .code {
            font-size: 32px;
        }
        .new-password,
        .confirm-password {
            font-size: 32px;
            padding-right: 50px;
        }
        .get-code {
            font-size: 24px;
        }
        .form-group {
            margin-top: 50px!important;
        }
        .hi-eye-close {
            width: 40px;
            height: 40px;
        }
        .hi-eye-close.new-icon {
            top: 260px;
        }
        .hi-eye-close.confirm-icon {
            top: 435px;
        }
        .bi-eye-fill {
            font-size: 35px;
        }
        .bi-eye-fill.new-icon {
            top: 253px;
        }
        .bi-eye-fill.confirm-icon {
            top: 428px;
        }
        .back-next-container {
            margin-top: 170px;
        }
        .back,
        .next {
            font-size: 28px;
        }
        .error-message {
            font-size: 26px;
        }
    }
    @media (width <= 912px) { 
        .back-next-container {
            margin-top: 190px;
        }
    }
    @media (width <= 820px) { 
        .forgot-password-title {
            font-size: 60px;
        }
        .hi-eye-close.new-icon {
            top: 240px;
        }
        .hi-eye-close.confirm-icon {
            top: 415px;
        }
        .bi-eye-fill.new-icon {
            top: 233px;
        }
        .bi-eye-fill.confirm-icon {
            top: 408px;
        }
        .back-next-container {
            margin-top: 120px;
        }
    }
    @media (width <= 540px) {   
        .forgot-password-title {
            font-size: 35px;
        }
        .email-label,
        .code-label,
        .password-label {
            font-size: 16px;
        }
        .email,
        .code {
            font-size: 16px;
        }
        .new-password,
        .confirm-password {
            font-size: 16px;
            padding-right: 30px;
        }
        .get-code {
            font-size: 16px;
        }
        .hi-eye-close {
            width: 25px;
            height: 25px;
        }
        .hi-eye-close.new-icon {
            top: 175px;
        }
        .hi-eye-close.confirm-icon {
            top: 305px;
        }
        .bi-eye-fill {
           font-size: 20px;
        }
        .bi-eye-fill.new-icon {
            top: 173px;
        }
        .bi-eye-fill.confirm-icon {
            top: 301px;
        }
        .error-message {
            font-size: 13px;
        }
        .back-next-container {
            margin-top: 80px;
        }
        .back,
        .next {
            font-size: 15px;
        }
    }
    @media (width <= 430px) {
        .forgot-password-title {
            font-size: 60px;
            text-align: center;
        }
        .email-label,
        .code-label,
        .password-label {
            font-size: 22px;
        }
        .email,
        .code {
            font-size: 20px;
        }
        .new-password,
        .confirm-password {
            font-size: 20px;
            padding-right: 40px;
        }
        .hi-eye-close {
            width: 30px;
            height: 30px;
        }
        .hi-eye-close.new-icon {
            top: 287px;
        }
        .hi-eye-close.confirm-icon {
            top: 431px;
        }
        .bi-eye-fill {
            font-size: 25px;
        } 
        .bi-eye-fill.new-icon {
            top: 285px;
        }
        .bi-eye-fill.confirm-icon {
            top: 428px;
        }
        .error-message {
            font-size: 16px;
        }
        .back-next-container {
            margin-top: 140px;
        }
        .back,
        .next {
            font-size: 18px;
        }
    }
    @media (width <= 390px) {
        .forgot-password-title {
            font-size: 50px;
        }
        .email-label,
        .code-label,
        .password-label {
            font-size: 18px;
        }
        .email,
        .code,
        .new-password,
        .confirm-password {
            font-size: 18px;
        }
        .get-code {
            font-size: 14px;
        }
        .hi-eye-close {
            width: 30px;
            height: 30px;
        }
        .hi-eye-close.new-icon {
            top: 253px;
        }
        .hi-eye-close.confirm-icon {
            top: 385px;
        }
        .bi-eye-fill {
            font-size: 25px;
        } 
        .bi-eye-fill.new-icon {
            top: 251px;
        }
        .bi-eye-fill.confirm-icon {
            top: 382px;
        }
        .error-message {
            font-size: 16px;
        }
        .back-next-container {
            margin-top: 95px;
        }
        .back,
        .next {
            font-size: 16px;
        }
    }
    @media (width <= 360px) {
        .forgot-password-title {
            font-size: 45px;
        }
        .email-label,
        .code-label,
        .password-label {
            font-size: 16px;
        }
        .email,
        .code,
        .new-password,
        .confirm-password {
            font-size: 14px;
        }
        .get-code {
            font-size: 13px;
        }
        .form-group {
            margin-top: 30px!important;
        }
        .hi-eye-close {
            width: 25px;
            height: 25px;
        }
        .hi-eye-close.new-icon {
            top: 220px;
        }
        .hi-eye-close.confirm-icon {
            top: 325px;
        }
        .bi-eye-fill {
            font-size: 20px;
        } 
        .bi-eye-fill.new-icon {
            top: 218px;
        }
        .bi-eye-fill.confirm-icon {
            top: 323px;
        }
        .error-message {
            font-size: 13px;
        }
        .back-next-container {
            margin-top: 80px;
        }
        .back,
        .next {
            font-size: 12px;
        }
    }
    @media (width <= 280px) {
        .new-password,
        .confirm-password {
            padding-right: 40px;
        }
        .hi-eye-close.new-icon {
            right: 20px;
        }
        .hi-eye-close.confirm-icon {
            right: 20px;
        }
        .bi-eye-fill.new-icon {
            right: 20px;
        }
        .bi-eye-fill.confirm-icon {
            right: 20px;
        }
    }
</style>