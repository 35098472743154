<template>
    <div id="app">
      <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'App',
    };
</script>

<style>
    @import "~bootstrap-icons/font/bootstrap-icons.css";
</style> 