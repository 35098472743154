<template>
    <h5 class="hair-free">Hair-free Morning!</h5>
    <h1 class="sign-in mt-2">Sign in</h1>
    <form class="form-container" @submit.prevent="handleSubmit">
        <div class="form-group mt-4">
            <label for="email" class="email-label">
                Email
            </label>
            <input 
                v-model="formData.email"
                id="email" 
                type="text" 
                class="form-control mt-2" 
                placeholder="Enter email address">
        </div>
        <div class="form-group mt-4">
            <i @click="toggleEye" 
                v-show="isHideEye" 
                class="bi bi-eye-fill">
            </i>
            <svg 
                @click="toggleEye"
                v-show="isHideEyeClose"
                xmlns="http://www.w3.org/2000/svg" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                class="humbleicons hi-eye-close">
                <path xmlns="http://www.w3.org/2000/svg" stroke-width="2" :d="icon.EYE_CLOSE"/>
            </svg>
            <label 
                for="emailPassword" 
                class="email-label">
                Password
            </label>
            <input 
                v-model="formData.password"
                id="password" 
                :type="type" 
                class="form-control mt-2" 
                placeholder="Enter your password">
        </div>
        <div class="error-message" v-if="errors.length">
            <ul class="error-container">
                <li v-for="(error, index) in errors" :key="index">
                    {{ error }}
                </li>
            </ul>
        </div>
        <button type="submit" class="btn btn-primary">
            Sign In
        </button>
    </form>
</template>

<script>
import * as icon from '@/assets/js/icon';
import { mapActions } from 'vuex';

export default {
    name: 'login-page',
    data() {
        return {
            icon,
            isHideEyeClose: true,
            isHideEye: false,
            type: 'password',
            formData: {
                email: '',
                password: '',
            },
            errors: [],
        }
    },
    methods: {
        ...mapActions('auth', ['login']),
        toggleEye() {
            this.isHideEye = !this.isHideEye;
            this.isHideEyeClose = !this.isHideEyeClose;
            this.isHideEye ? this.type = 'text' : this.type = 'password';
        },
        handleSubmit(event) {
            event.preventDefault();
            this.errors = [];

            switch(true) {
                case !this.formData.email && !this.formData.password: 
                    this.errors.push("Please input your email and password");
                    break;
                case !this.formData.email: 
                    this.errors.push("Please input your email");
                    break;
                case !this.formData.password:
                    this.errors.push("Please input your password");
                    break;
                case this.formData.email && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.formData.email):
                    this.errors.push("Please enter a valid email address");
                    break;
            }

            if (this.errors == 0) {
                this.login(this.formData)
                .then(() => {
                    this.$router.push('/main');
                })
                .catch(() => {
                    const errorMessage = "Invalid email address or password";

                    if (!this.errors.includes(errorMessage)) {
                        this.errors.push(errorMessage);
                    }
                });
            }
        }
    }
}
</script>

<style scoped>
    .hair-free {
        font-weight: 500;
        font-size: 17px;
    }
    .sign-in {
        font-size: 48px;
        background-color: transparent;
    }
    .email-label {
        font-weight: 500;
        font-size: 18px;
    }
    .form-control {
        font-size: 13px;
        padding: 10px;
        box-shadow:  1px 1px 1px #B4B0B1;
        border: 1px solid #B4B0B1;
        outline: 0;
        padding-right: 35px;
        position: relative;
    }
    .form-control:focus {
        border: 1px solid #B4B0B1;
        box-shadow:  1px 1px 1px #B4B0B1;
    }
    .bi-eye-fill {
        position: absolute; 
        right: 48px;
        top: 291px;
        z-index: 1;
        font-size: 20px;
        color: #B0B0B0;
        cursor: pointer;        
    }
    .btn-primary {
        width: 100%;
        background-color: #6146DF;
        margin-top: 80px;
        padding: 10px;
        border-radius: 10px; 
        box-shadow: -2px 5px 17px 4px rgba(97,70,223,0.47);
    }
    .btn-primary:hover {
        background-color: #6146DF;
    }
    .hi-eye-close {
        cursor: pointer;
        width: 25px;
        height: 25px;
        position: absolute;
        top: 294px;
        right: 45px;
        z-index: 1;
        color: #B0B0B5;
    }
    .error-message {
        position: absolute;
        margin-top: 5px;
        font-size: 13px;
        color: #FF0000;
    }
    .error-container {
        list-style-type: none;
        padding: 10px 5px;
    }

    /* responsive screen size */
    @media (width <= 1024px) {
        .hair-free {
            font-size: 25px;
        }
        .sign-in {
            font-size: 80px;
        }
        .email-label {
            font-weight: 500;
            font-size: 32px;
        }
        .form-control {
            font-size: 32px;
        }
        .form-container {
            margin-top: 50px;
        }
        .hi-eye-close {
            width: 40px;
            height: 40px;
            right: 60px;
            top: 448px;
        }
        .bi-eye-fill {
            font-size: 35px;
            right: 60px;
            top: 443px;
        }
        .btn-primary {
            font-size: 28px;
            margin-top: 180px;
        }
        .error-message {
            font-size: 24px;
        }
    }
    @media (width <= 820px) { 
        .btn-primary {
            margin-top: 100px;
        }
    }
    @media (width <= 540px) {
        .hair-free {
            font-size: 14px;
        }
        .sign-in {
            font-size: 40px;
        }
        .email-label {
            font-weight: 500;
            font-size: 16px;
        }
        .form-control {
            font-size: 16px;
        }
        .form-container {
            margin-top: 0px;
        }
        .hi-eye-close {
            width: 25px;
            height: 25px;
            right: 50px;
            top: 282px;
        }
        .bi-eye-fill {
            font-size: 20px;
            right: 52px;
            top: 280px;
        }
        .btn-primary {
            font-size: 15px;
            margin-top: 100px;
        }
        .error-message {
            font-size: 13px;
            margin-top: 18px;
        }
    }
    @media (width <= 430px) {
        .hair-free {
            font-size: 20px;
        }
        .sign-in {
            font-size: 60px;
        }
        .email-label {
            font-weight: 500;
            font-size: 22px;
        }
        .form-control {
            font-size: 20px;
        }
        .form-container {
            margin-top: 60px;
        }
        .hi-eye-close {
            width: 30px;
            height: 30px;
            top: 375px;
            right: 45px;
        }
        .bi-eye-fill {
            font-size: 25px;
            right: 45px;
            top: 370px;
        } 
        .btn-primary {
            font-size: 20px;
            margin-top: 200px;
        }
        .error-message {
            font-weight: 500;
            font-size: 18px;
        }
    }
    @media (width <= 390px) {
        .hair-free {
            font-size: 16px;
        }
        .sign-in {
            font-size: 50px;
        }
        .email-label {
            font-weight: 500;
            font-size: 18px;
        }
        .form-control {
            font-size: 18px;
        }
        .form-container {
            margin-top: 40px;
        }
        .hi-eye-close {
            width: 30px;
            height: 30px;
            top: 320px;
            right: 45px;
        }
        .bi-eye-fill {
            font-size: 25px;
            right: 45px;
            top: 318px;
        } 
        .btn-primary {
            font-size: 16px;
            margin-top: 150px;
        }
        .error-message {
            font-weight: 500;
            font-size: 18px;
        }
    }
    @media (width <= 360px) {
        .hair-free {
            font-size: 14px;
        }
        .sign-in {
            font-size: 45px;
        }
        .email-label {
            font-weight: 500;
            font-size: 16px;
        }
        .form-control {
            font-size: 14px;
        }
        .form-container {
            margin-top: 40px;
        }
        .hi-eye-close {
            width: 25px;
            height: 25px;
            top: 302px;
            right: 45px;
        }
        .bi-eye-fill {
            font-size: 20px;
            right: 48px;
            top: 295px;
        } 
        .btn-primary {
            font-size: 12px;
            margin-top: 100px;
        }
        .error-message {
            margin-top: 0px;
            font-size: 12px;
        }
    }
    @media (width <= 280px) {
        .hi-eye-close {
            width: 20px;
            height: 20px;
            right: 20px;
        }
        .bi-eye-fill {
            font-size: 20px;
            right: 20px;
            top: 297px;
        } 
    }
</style>