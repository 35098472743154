import axios from 'axios';
import Cookies from 'js-cookie';
import { API_URL } from '@/config';

const state = {
    token: null,
    user: null,
}

const getters = {

}

const actions = {
    async login({ commit }, credentials) {
        try {
            const response = await axios.post(API_URL + '/v1/login', credentials);
            const { jwt, first_name, last_name, id } = response.data;

            Cookies.set('auth_token', jwt, { expires: 1, secure: true });
            commit('setToken', jwt);
            commit('setUser', { first_name, last_name, id });

            localStorage.setItem('user', JSON.stringify({ first_name, last_name, id }));

          } catch (error) {
            throw new Error(error.message);
          }
    },
    logout({ commit }) {
        commit('clearToken'); 
        Cookies.remove('auth_token');
        localStorage.removeItem('user');
    },
};

const mutations = {
    setToken(state, token) {
        state.token = token;
    },
    clearToken(state) {
        state.token = null;
    },
    setUser(state, user) {
        state.user = user;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}