<template>
    <div class="frame-container">
        <iframe class="frame"
            title="ProductSalesPerEmployee"
            src="https://app.powerbi.com/reportEmbed?reportId=74300f3b-2e5e-4140-b698-4915d85be553&autoAuth=true&ctid=de70df2e-9a73-48dc-af26-dcec8dc8a16d" 
            frameborder="0"
            allowFullScreen="true">
        </iframe>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('auth', ['user']),
    },
}
</script>

<style>

</style>