<template>
    <div class="sidebar-container">
        <div class="sidebar-list">
            <ul class="list-container">
                <li :class="{ 'dashboard-list': true, 
                    'active': isDashboardTab() }"
                    @click="handleTabClick('Dashboard')">
                    <svg 
                        class="sidebar-icon"
                        viewBox="0 0 50 50" 
                        :fill="isDashboardTab() ? 'white' : 'black'" 
                        xmlns="http://www.w3.org/2000/svg">
                        <path :d="icon.DASHBOARD" :fill="isDashboardTab() ? 'white' : 'black'"/>
                    </svg>
                    <label @click="navigate('Dashboard')">Dashboard</label>
                </li>
                <li :class="{ 'active': isScheduleTab() }"
                    @click="handleTabClick('Schedule')" v-show="isShow">
                    <svg 
                        class="sidebar-icon"
                        viewBox="0 0 50 50" 
                        :fill="isScheduleTab() ? 'white' : 'black'"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1187_27226)">
                            <path 
                                fill-rule="evenodd" 
                                clip-rule="evenodd" 
                                :d="icon.SCHEDULE_TOP" 
                                :fill="isScheduleTab() ? 'white' : 'black'"/>
                            <path 
                                :d="icon.SCHEDULE_MIDDLE" 
                                :fill="isScheduleTab() ? 'white' : 'black'"/>
                            <path 
                                fill-rule="evenodd" 
                                clip-rule="evenodd" 
                                :d="icon.SCHEDULE_BOTTOM" 
                                :fill="isScheduleTab() ? 'white' : 'black'"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1187_27226">
                            <rect width="50" height="50" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                    <input type="checkbox" id="schedule" v-model="schedule" />
                    <label for="schedule">Schedule</label>
                    <ul class="submenu">
                        <li @click="navigate('BranchSchedule')">Branch Schedule</li>
                        <li @click="navigate('EmployeeSchedule')">Employee Schedule</li>
                    </ul>
                </li>
                <li :class="{ 'active': isPerformanceTab() }"
                    @click="handleTabClick('Performance')">
                    <svg 
                        class="sidebar-icon"
                        viewBox="0 0 50 50" 
                        :fill="isPerformanceTab() ? 'white' : 'black'" 
                        xmlns="http://www.w3.org/2000/svg">
                        <path :d="icon.PERFORMANCE_BOTTOM" 
                            :fill="isPerformanceTab() ? 'white' : 'black'"/>
                        <path :d="icon.PERFORMANCE_TOP"
                            :fill="isPerformanceTab() ? 'white' : 'black'" />
                    </svg>
                    <input type="checkbox" id="performance" v-model="performance"/>
                    <label for="performance">Performance</label>
                    <ul class="submenu">
                        <li>
                            <input type="checkbox" id="service-sales"/>
                            <label for="service-sales">Service Sales</label>
                            <ul class="submenu">
                                <li @click="navigate('Branch')">Branch</li>
                                <li @click="navigate('Employee')" v-show="isShow">Employee</li>
                            </ul>
                        </li> 
                        <li>
                            <input type="checkbox" id="product-sales"/>
                            <label for="product-sales">Product Sales</label>
                            <ul class="submenu">
                                <li @click="navigate('BranchProduct')">Branch</li>
                                <li @click="navigate('EmployeeProduct')" v-show="isShow">Employee</li>
                            </ul>
                        </li>
                        <li @click="navigate('ClientProfile')">Client Profile</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div @click="handleLogout" class="logout-container">
            <i class="bi bi-box-arrow-right"></i>
            <label class="logout">Logout</label>
        </div>
    </div>
    <div class="mobile-sidebar-container" :style="{ left: isSidebarClose ? '-100%' : '0%' }">
        <div class="sidebar-list">
            <div class="close-icon-container">
                <i class="bi bi-x-lg icon" @click="toggleSidebar"></i>
            </div>
            <div class="container-sm details-container">
                <div class="customer-container">
                    <img src="https://picsum.photos/200/300" alt="Avatar" class="avatar">
                    <div class="customer-details" v-if="user">
                        <label class="customer">
                            {{ user.first_name }} {{ user.last_name }}
                        </label>
                        <label class="role">
                            {{ user.role || 'Admin' }}
                        </label>
                    </div>
                </div> 
            </div>
            <ul class="list-container">
                <li :class="{ 'dashboard-list': true, 
                    'active': isDashboardTab() }"
                    @click="handleTabClick('Dashboard')">
                    <svg 
                        class="sidebar-icon"
                        viewBox="0 0 50 50" 
                        :fill="isDashboardTab() ? 'white' : 'black'" 
                        xmlns="http://www.w3.org/2000/svg">
                        <path :d="icon.DASHBOARD" :fill="isDashboardTab() ? 'white' : 'black'"/>
                    </svg>
                    <label class="list-title">Dashboard</label>
                </li>
                <li :class="{ 'active': isScheduleTab() }"
                    @click="handleTabClick('Schedule')" v-show="isShow">
                    <svg 
                        class="sidebar-icon"
                        viewBox="0 0 50 50" 
                        :fill="isScheduleTab() ? 'white' : 'black'"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1187_27226)">
                            <path 
                                fill-rule="evenodd" 
                                clip-rule="evenodd" 
                                :d="icon.SCHEDULE_TOP" 
                                :fill="isScheduleTab() ? 'white' : 'black'"/>
                            <path 
                                :d="icon.SCHEDULE_MIDDLE" 
                                :fill="isScheduleTab() ? 'white' : 'black'"/>
                            <path 
                                fill-rule="evenodd" 
                                clip-rule="evenodd" 
                                :d="icon.SCHEDULE_BOTTOM" 
                                :fill="isScheduleTab() ? 'white' : 'black'"/>
                        </g>
                        <defs>
                        <clipPath id="clip0_1187_27226">
                            <rect width="50" height="50" fill="white"/>
                        </clipPath>
                        </defs>
                    </svg>
                    <input type="checkbox" id="mobile-schedule"/>
                    <label class="list-title" for="mobile-schedule">Schedule</label>
                    <ul class="submenu">
                        <li @click="navigate('BranchSchedule')">Branch Schedule</li>
                        <li @click="navigate('EmployeeSchedule')">Employee Schedule</li>
                    </ul>
                </li>
                <li :class="{ 'active': isPerformanceTab() }"
                    @click="handleTabClick('Performance')">
                    <svg 
                        class="sidebar-icon"
                        viewBox="0 0 50 50" 
                        :fill="isPerformanceTab() ? 'white' : 'black'" 
                        xmlns="http://www.w3.org/2000/svg">
                        <path :d="icon.PERFORMANCE_BOTTOM" 
                            :fill="isPerformanceTab() ? 'white' : 'black'"/>
                        <path :d="icon.PERFORMANCE_TOP"
                            :fill="isPerformanceTab() ? 'white' : 'black'"/>
                    </svg>
                    <input type="checkbox" id="mobile-performance"/>
                    <label class="list-title" for="mobile-performance">Performance</label>
                    <ul class="submenu">
                        <li>
                            <input type="checkbox" id="mobile-service-sales"/>
                            <label for="mobile-service-sales">Service Sales</label>
                            <ul class="submenu">
                                <li @click="navigate('Branch')">Branch</li>
                                <li @click="navigate('Employee')" v-show="isShow">Employee</li>
                            </ul>
                        </li> 
                        <li>
                            <input type="checkbox" id="mobile-product-sales"/>
                            <label for="mobile-product-sales">Product Sales</label>
                            <ul class="submenu">
                                <li @click="navigate('BranchProduct')">Branch</li>
                                <li @click="navigate('EmployeeProduct')" v-show="isShow">Employee</li>
                            </ul>
                        </li>
                        <li @click="navigate('ClientProfile')">Client Profile</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div @click="handleLogout" class="logout-container">
            <i class="bi bi-box-arrow-right"></i>
            <label class="logout">Logout</label>
        </div>
    </div>
</template>

<script>
import * as icon from '@/assets/js/icon';
import * as helpers from '@/utils/helper';
import eventBus from '@/store/eventBus';

import { mapActions } from 'vuex';

export default {
    emits: ['updateTitle'],
    data() {
        return {
            icon,
            tab: 'Dashboard',
            isSidebarClose: true,
            schedule: false,
            performance: false,
            isShow: false,
            user: null
        }
    },
    created() {
        eventBus.on('toggleSidebar', this.toggleSidebar);
        this.user = helpers.userData();
    },
    methods: {
        ...mapActions('auth', ['logout']),
        handleLogout() {
            this.logout();
            this.$router.push('/');
        },
        isDashboardTab() {      
            if (this.tab === 'Dashboard') {
                this.$router.push({ name: 'Dashboard' });
                return this.tab === 'Dashboard';
            }
        },
        isScheduleTab() {
            return this.tab === 'Schedule';
        },
        isPerformanceTab() {
            return this.tab === 'Performance';
        },
        handleTabClick(tabValue) {
            this.tab = tabValue;
            this.$emit('updateTitle', tabValue);

            switch (tabValue) {
                case 'Dashboard': 
                    this.isSidebarClose = true;
                    this.schedule       = false;
                    this.performance    = false;
                    break;
                case 'Schedule':
                    this.performance = false;
                    break;
                case 'Performance':
                    this.schedule = false;
                    break;
            }
        },
        navigate(routeName) {
            this.$router.push({ name: routeName, params: {}, query: {} });
            this.isSidebarClose = true;
        },
        toggleSidebar() {
            this.isSidebarClose = !this.isSidebarClose;
        },
    }
}
</script>

<style scoped>
    .sidebar-container {
        position: relative;
        height: 100vh;
        width: 250px;
        box-shadow: -1px -3px 3px 5px #C0C0C0;
        display: flex;
        flex-direction: column;
    }
    .sidebar-list {
        height: 95%;
        width: 100%;
        background-image: url(
            '~@/assets/background-images/sidebar.png'
        );
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
     .logout-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        cursor: pointer;
        background-color: #EFEFEF;
        height: 50px;
        padding: 10px;
    }
    .bi-box-arrow-right {
        background-color: #000000;
        padding: 3px 3px 1px 5px;
        font-size: 13px;
        color: #FFFFFF;
        border-radius: 10px;
    }
    .logout {
        font-size: 16px;
        bottom: 12px;
        font-weight: 500;
        cursor: pointer;
    }
    .opener,
    .closer,
    .dashboard {
        cursor: pointer;
        font-size: 14px;
        line-height: 2;
    }
    .list-container {
        position: absolute;
        top: 30%;
        list-style-type: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        list-style: none;
        padding: 0;
        width: 95%;
    }
    .list-container li {
        margin-bottom: 10px;
        font-weight: 400;
        cursor: pointer;
    }
    .list-container input {
        position: absolute;
        opacity: 0;
    }
    .submenu {
        margin-top: 10px;
        display: none;
        padding-left: 20px;
        list-style-type: none;
        font-size: 14px;
        line-height: 1;
        font-weight: 500;
    }
    .list-container label {
        cursor: pointer;
    }
    .list-container input:checked + label + .submenu,
    .list-container .submenu input:checked + label + .submenu {
        display: block;
    }
    .icons {
        font-size: 25px;
        margin: 0px 5px 0px 5px;
    }
    .active {
        background-color: #989898;
        color: #FFFFFF;
        width: 100%;
        padding: 5px;
        border-radius: 1px 20px 20px 1px;
        font-size: 18px;
    }
    .sidebar-icon {
        width: 25px;
        height: 25px;
        margin: 0px 5px;
    } 

    @media (min-width: 768px) {
        .mobile-sidebar-container {
            display: none;
        }
    }
    @media (max-width: 767px) {
        .sidebar-container {
            display: none;
        }
        .mobile-sidebar-container {
            z-index: 2;
            display: flex;
            flex-direction: column;
            transition: 0.5s;
            overflow: auto;
            position: relative;
            height: 100vh;
            width: 100%;
        }
        .sidebar-list {
            width: 100%;
            position: relative;
            background-image: url(
                '~@/assets/background-images/tablet-sidebar.png'
            );
            display: flex;
            flex-direction: column;
            overflow: auto;
        }
        .close-icon-container {
            text-align: right;
            padding: 30px;
        }
        .icon {
            font-size: 32px;
            cursor: pointer;
            color: #FFFFFF;
        }
        .details-container {
            position: relative;
            padding: 0px 50px;
        }
        .customer-container {
            padding: 25px 15px;
            display: flex;
            flex-direction: row;
            gap: 15px;
            border-radius: 15px;
            background-color: #FFFFFF;
            box-shadow:  1px 1px 1px 1px #B4B0B1;
            word-break: break-word;
        }
        .customer {
            font-size: 18px;
            font-weight: bolder;
            padding: 10px 0px 0px 10px;
            margin: 0px;
        }
        .customer-details {
            display: flex;
            flex-direction: column;
        }
        .avatar {
            vertical-align: middle;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 1px solid #000000;
            margin-top: 10px;
        }
        .role {
            padding: 3px 0px 0px 10px;
            font-size: 16px;
            font-weight: 500;
        }
        .list-container {  
            position: relative;
            top: 50px;
            width: 100%;
        }
        .list-title {
            font-size: 24px;
        }
        .active {
            border-radius: 0px;
            background-color: rgba(154, 154, 154, 0.8);
        }
        .list-container li {
            font-size: 18px;
        }
    }
    @media (max-width: 413px) {
        .details-container {
            padding: 0px 10px;
        }
        .role {
            font-size: 14px;
        }
        .list-title {
            font-size: 20px;
        }
        .list-container li {
            font-size: 16px;
        }
    }   
    @media (max-width: 290px) {
        .customer-container {
            gap: 0;
        }
        .role {
            font-size: 12px;
        }
    }
</style>