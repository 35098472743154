import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

import Cookies from 'js-cookie';

const token = Cookies.get('auth_token');

if (token) {
    store.commit('auth/setToken', token);
}

const app = createApp(App);

const userData = localStorage.getItem('user');

if (userData) {
    const { first_name, last_name, id } = JSON.parse(userData);
    store.commit('auth/setUser', { first_name, last_name, id });
}

app.use(router);
app.use(store);

app.mount('#app');
