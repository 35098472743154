<template>
    <div class="frame-container" v-if="filteredEmbedded">
        <iframe
            v-for="(data) in filteredEmbedded"
            :key="data.id"
            class="frame"
            title="Report Section"
            :src="currentPageUrl"
            frameborder="0"
            allowFullScreen="true">
        </iframe>
        <div class="pagination">
            <button 
                @click="prevPage" 
                :disabled="currentPage == 1"> 
                {{ previous }}
            </button>
                <label class="page-number">
                    {{ currentPage }} of {{ totalPages ?? defaultPageNo }} 
                </label>
            <button 
                @click="nextPage" 
                :disabled="currentPage == totalPages">
                {{ next }}
            </button>
        </div> 
    </div>
    <div v-else class="no-data">
        NO DATA AVAILABLE
    </div>
</template>

<script>
    import { ref, computed, watchEffect } from 'vue';
    import * as helpers from '@/utils/helper';

    export default {
        props: {
            name: {
                type: String,
                required: true
            }
        },
        setup(props) {
            const user          = helpers.userData();
            const next          = ">";
            const previous      = "<";
            const defaultPageNo = 1;
          
            const filteredEmbedded = ref(helpers.filteredEmbedded(props.name));
            const totalPages       = ref(0);
            const currentPage      = ref(1);

            watchEffect(() => {
                if (filteredEmbedded.value) {
                    totalPages.value = filteredEmbedded.value.urls?.length;
                }
            });

            const nextPage = () => {
                if (currentPage.value < totalPages.value) {
                    currentPage.value++;
                } 
            };
            
            const prevPage = () => {
                if (currentPage.value > defaultPageNo) {
                    currentPage.value--;
                }
            };

            const currentPageUrl = computed(() => {
                if (filteredEmbedded.value) {
                    return filteredEmbedded.value.urls[
                        currentPage.value - 1
                    ]
                }

                return [];
            });

            return {
                filteredEmbedded,
                currentPageUrl,
                currentPage,
                totalPages,
                prevPage,
                nextPage,
                next,
                user,
                previous,
                defaultPageNo
            }
        }
    }
</script>

<style></style>