import { createRouter, createWebHistory } from 'vue-router';
import Main from '@/views/main/MainComponent.vue';
import Login from '@/views/LoginAndForgot/LoginAndForgotLayout.vue';
import BranchSchedule from '@/views/main/Schedule/BranchScheduleComponent.vue';
import EmployeeSchedule from '@/views/main/Schedule/EmployeeScheduleComponent.vue';
import Branch from '@/views/main/ServiceSales/BranchComponent.vue';
import Employee from '@/views/main/ServiceSales/EmployeeComponent.vue';
import Dashboard from '@/views/main/DashboardComponent.vue';
import BranchProduct from '@/views/main/ProductSales/BranchProductSales.vue';
import EmployeeProduct from '@/views/main/ProductSales/EmployeeProductSales.vue';
import ClientProfile from '@/views/main/ClientProfile.vue';
import Cookies from 'js-cookie';

const routes = [
    {
        path: '/:catchAll(.*)',
        redirect: () => {
            const isAuthenticated = Cookies.get('auth_token');
            const redirectRoute = isAuthenticated ? '/main' : '/';

            return redirectRoute;
        }
    },
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            title: 'Franchisee Web | Login'
        },
        beforeEnter: (to, from, next) => {
            const isAuthenticated = Cookies.get('auth_token');
            const redirectRoute = '/main';

            if (isAuthenticated) {
                next(redirectRoute);
            } else {
                next();
            }
        }
    },
    {
        path: '/main',
        name: 'Main',
        component: Main,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path:'',
                name: "Dashboard",
                component: Dashboard,
                meta: {
                    title: 'Franchisee Web | Dashboard'
                }
            },
            {
                path: '/main/branch-schedule',
                name: 'BranchSchedule',
                component: BranchSchedule,
                meta: {
                    title: 'Franchisee Web | Branch Schedule'
                } 
            },
            {
                path: '/main/employee-schedule',
                name: 'EmployeeSchedule',
                component: EmployeeSchedule,
                meta: {
                    title: 'Franchisee Web | Employee Schedule'
                }
            },
            {
                path: '/main/branch',
                name: 'Branch',
                component: Branch,
                meta: {
                    title: 'Franchisee Web | Branch Service'
                }
            },
            {
                path: '/main/employee',
                name: 'Employee',
                component: Employee,
                meta: {
                    title: 'Franchisee Web | Employee Service'
                }
            },
            {
                path: '/main/branch-product',
                name: 'BranchProduct',
                component: BranchProduct,
                meta: {
                    title: 'Franchisee Web | Branch Product'
                }
            },
            {
                path: '/main/employee-product',
                name: 'EmployeeProduct',
                component: EmployeeProduct,
                meta: {
                    title: 'Franchisee Web | Employee Product'
                }
            },
            {
                path: '/main/client-profile',
                name: 'ClientProfile',
                component: ClientProfile,
                meta: {
                    title: 'Franchisee Web | Client Profile'
                }
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    window.document.title = to.name ? to.meta.title : 'Franchisee Web';
    const REQUIRES_AUTH   = to.matched.some(record => record.meta.requiresAuth);

    if (REQUIRES_AUTH && !Cookies.get('auth_token')) {
        next('/');
    } else {
        next();
    }
});

export default router;
