<template>
    <EmbeddedPaginationVue name="dashboard"/>
</template>

<script>
    import EmbeddedPaginationVue from '@/components/EmbeddedPagination.vue';

    export default {
        components: {
            EmbeddedPaginationVue
        }
    }
</script>

<style>
    .frame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        z-index: 0;
        padding: 0;
    }
    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    .pagination button {
        margin: 0 5px;
        border: none;
        background-color: transparent;
        font-size: 20px;
    }
    .pagination .active {
        font-weight: bold;
    }
    .page-number {
        border: none;
        background-color: transparent;
        font-weight: 500;
        font-size: 14px;
    }
    .no-data {
        font-size: 48px;
        font-weight: 500;
        letter-spacing: 1px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>