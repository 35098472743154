import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import embeddedData from '../assets/embedded.json';

export function userData() {
    const store = useStore();
    const user  = computed(() => store.getters['user/user']);

    return user;
}

export function filteredEmbedded(name) {
    const user = userData();
    const filteredEmbedded = ref([]);
    
    setTimeout(() => {
        filteredEmbedded.value = embeddedData.find(
            data => data.name == name
        ).items?.find(
            item => item.email == user.value.email
        );
    }, 2500);

    return filteredEmbedded;
}
