<template>
    <div class="login-forgot-container">
        <div id="slider" 
            class="carousel slide carousel-fade" 
            data-bs-ride="carousel"  
            data-bs-pause="false">
            <div class="carousel-inner">
                <div v-for="(slide, index) in slides" 
                    :key="index" 
                    :class="{ 
                        'carousel-item': true,
                        'active': slide.name == 'laybare-plus'
                    }"
                    data-bs-interval="2500">
                    <img :src="getImageUrl(slide)" class="images">
                </div>
            </div>
        </div>
        <div v-show="isShowLogin" 
            class="login">
            <Login/>
            <label @click="displayForgotPassword" 
                class="forgot-password">
                Forgot password
            </label>
        </div>
        <div v-show="isShowForgotPassword" 
            class="forgot-password-container">
            <ForgotPassword @backToLogin="backToLogin"/>
        </div>
    </div>
</template> 

<script>
    import Login from './Login.vue';
    import ForgotPassword from './ForgotPassword.vue';

    import * as bootstrap from 'bootstrap';
    import images from '@/assets/js/images';
    import { ref, onMounted, onUnmounted, computed } from 'vue';
    
    export default {
        name: 'LoginAndForgot',
        components: {
            Login,
            ForgotPassword
        },
        setup() {
            const desktopSize = 1024;
            const mobileSize  = 768;

            const slidesData = [
                { 
                    name: 'laybare-plus', 
                    image: images.laybarePlus.default,
                    tabletImage: images.laybarePlus.tablet,
                    mobileImage: images.laybarePlus.mobile
                },
                { 
                    name: 'passionails', 
                    image: images.passionails.default, 
                    tabletImage: images.passionails.tablet, 
                    mobileImage: images.passionails.mobile
                },
                { 
                    name: 'laybare', 
                    image: images.laybare.default, 
                    tabletImage: images.laybare.tablet, 
                    mobileImage: images.laybare.mobile 
                },
                { 
                    name: 'lavish', 
                    image: images.lavish.default, 
                    tabletImage: images.lavish.tablet, 
                    mobileImage: images.lavish.mobile 
                }
            ];

            const slides = ref(slidesData.map(slide => ({
                name: slide.name,
                image: slide.image,
                tabletImage: slide.tabletImage,
                mobileImage: slide.mobileImage
            })));

            const windowWidth    = ref(window.innerWidth);
            const isdesktopSize  = computed(() =>  windowWidth.value >= desktopSize);
            const isMobileScreen = computed(() => windowWidth.value < mobileSize);

            const handleResize = () => { 
                windowWidth.value = window.innerWidth; 
            };

            onMounted(() => {
                window.addEventListener('resize', handleResize);
                new bootstrap.Carousel('#slider').cycle();
            });

            onUnmounted(() => {
                window.removeEventListener('resize', handleResize);
            });

            const getImageUrl = (slide) => {
                switch(true) {
                    case isdesktopSize.value:
                        return slide.image;
                    case isMobileScreen.value: 
                        return slide.mobileImage;
                    default:
                        return slide.tabletImage;
                }
            };
            
            const isShowLogin          = ref(true);
            const isShowForgotPassword = ref(false);

            const toggleLoginForgotPassword = () => {
                isShowLogin.value          = !isShowLogin.value;
                isShowForgotPassword.value = !isShowForgotPassword.value;
            };

            const displayForgotPassword = () => {
                toggleLoginForgotPassword(); 
            };

            const backToLogin = () => {
                toggleLoginForgotPassword(); 
            };

            return {
                slides,
                isShowLogin,
                isShowForgotPassword,
                displayForgotPassword,
                backToLogin,
                getImageUrl
            };
        },
    }
</script>
<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
    * {
        font-family: 'Poppins', sans-serif;
    }
    .login-forgot-container {
        position: relative;
    }
    .login,
    .forgot-password-container {
        position: absolute;
        top: 250px;
        right: 40px;   
        background-color: #F6E9ED;
        width: 500px;
        height: 490px;
        padding: 40px;
        border-radius: 30px;
    }
    .forgot-password {
        position: absolute;
        top: 335px;
        right: 40px;
        z-index: 1;
        font-size: 13px;
        color: #6146DF;
        cursor: pointer;
    }
    .forgot-password:hover {
        text-decoration: underline;
    }
    .carousel-fade {
        z-index: 0;
    }
    .images {
        height: 100vh;
        width: 100vw;
    }

    @media (width <= 1024px) {
        .login,
        .forgot-password-container  {
            width: 850px;
            height: 800px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .forgot-password {
            top: 510px;
            font-size: 24px;
        }
        .images {
            max-height: 300!important;
        }
    }
    @media (width <= 820px) {
        .login,
        .forgot-password-container {
            width: 700px;
            height: 700px;
        }
    }
    @media (width <= 540px) {
        .login,
        .forgot-password-container  {
            width: 400px;
            height: 500px;
        }
        .forgot-password {
            top: 320px;
            font-size: 16px;
        }
    }
    @media (width <= 430px) {
        .login,
        .forgot-password-container  {
            height: 700px;
            background-color: rgba(246,233,237,0.8);
        }
        .forgot-password {
            top: 590px;
            font-size: 16px;
            transform: translate(-65%, -50%);
        }
    }
    @media (width <= 390px) {
        .login,
        .forgot-password-container {
            width: 380px;
            height: 600px;
        }
        .forgot-password {
            top: 490px;
            font-size: 16px;
            transform: translate(-60%, -50%);
        }
    }
    @media (width <= 375px) {
        .login,
        .forgot-password-container {
            width: 350px;
        }
    }
    @media (width <= 360px) {
        .login,
        .forgot-password-container {
            width: 350px;
            height: 520px;
        }
        .forgot-password {
            top: 420px;
            font-size: 14px;
            transform: translate(-60%, -50%);
        }
    }
    @media (width <= 280px) {
        .login,
        .forgot-password-container {
            width: 270px;
            height: 510px;
            padding: 40px 10px;
        }
        .forgot-password {
            transform: translate(-30%, -50%);
        }
    }
</style>
  