function importImages(contextRequire) {
    const images = {};

    contextRequire.keys().forEach((fileName) => {
        const fileNameParts = fileName.replace('./', '').replace('.png', '').split('-');
        
        const name    = fileNameParts.shift();
        const variant = fileNameParts.join('-');

        if (!images[name]) {
            images[name] = {};
        }
    
        if (variant) {
            images[name][variant] = contextRequire(fileName);
        } else {
            images[name]['default'] = contextRequire(fileName);
        }
    });
  
        return images;
    }
  
const images = importImages(
    require.context('@/assets/background-images', false, /\.(png|jpe?g|svg)$/)
);
  
const IMAGES = {
    laybarePlus: {
        default: images.laybareplus.default,
        tablet: images.laybareplus.tablet,
        mobile: images.laybareplus.mobile,
    },
    passionails: {
        default: images.passionails.default,
        tablet: images.passionails.tablet,
        mobile: images.passionails.mobile,
    },
    laybare: {
        default: images.laybare.default,
        tablet: images.laybare.tablet,
        mobile: images.laybare.mobile,
    },
    lavish: {
        default: images.lavish.default,
        tablet: images.lavish.tablet,
        mobile: images.lavish.mobile,
    },
};

export default IMAGES;