<template>
    <div class="container content-container">
        <div class="row">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    import { useStore } from 'vuex';

    export default {
        name: 'ContentComponent',
        setup() {
            const store = useStore()
            store.dispatch('user/getUser');
        }
    }
</script>

<style scoped>
    .content-container {
        margin-top: 115px;
        max-width: 85%;
        z-index: 1; 
        border-radius: 10px;
        border: 2px solid #000000;
        padding: 0px;
        overflow: hidden;
        margin-bottom: 2vh;
        background-color: #FFFFFF;
        position: relative;
        margin-right: 1%;
    }

    @media (max-width: 767px) {
        .content-container {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            height: 85%;
        }
    }
    @media (max-width: 540px) {
        .content-container {
            height: 80%;
        }
    }
    @media (max-width: 430px) {
        .content-container {
            height: 85%;
        }
    }
    @media (max-width: 390px) {
        .content-container {
            height: 83%;
        }
    }
    @media (max-width: 375px) {
        .content-container {
            height: 79%;
        }
    }
</style>