<template>
    <div class="main-container">
        <NavbarComponent :pageTitle="pageTitle"/>
        <div class="sidebar-content-container">
            <SidebarComponent @updateTitle="updatePageTitle"/>
            <ContentComponent/>
        </div>
    </div>
</template>

<script>
import SidebarComponent from './SidebarComponent.vue';
import NavbarComponent from './NavbarComponent.vue';
import ContentComponent from './ContentComponent.vue';
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('auth', ['isLoggedIn']),
    },
    components: {
        SidebarComponent,
        NavbarComponent,
        ContentComponent
    },
    data() {
        return {
            pageTitle: 'Dashboard',
        }
    },
    methods: {
        updatePageTitle(title) {
            this.pageTitle = title;
        }
    },
}
</script>

<style scoped>
    * {
        font-family: 'Poppins', sans-serif;
        padding: 0;
        margin: 0;
    }
    .main-container {
        position: relative;
        width: 100%;
        height: 100vh;
        background-color: #EFEFEF;
    }
    .sidebar-content-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    @media (width <= 767px) {
        .main-container {
            background-color: #FFFFFF;
        }
        .sidebar-content-container {
            gap: 0;
        }
    }
</style>