<template>
    <div class="frame-container">
        <iframe class="frame"
            v-if="filteredData"
            title="Report Section"
            :src="filteredData.urls" 
            frameborder="0"
            allowFullScreen="true">
        </iframe>
        <div v-else class="no-data">
            NO DATA AVAILABLE
        </div>
    </div>
</template>

<script>
    import * as helpers from '@/utils/helper';

    export default {
        setup() {
            const filteredEmbedded = helpers.filteredEmbedded('branch_product');
            const filteredData = filteredEmbedded ? filteredEmbedded : null;
            
            return { filteredData }
        }
    }
</script>

<style>     

</style>