<template>
    <EmbeddedPaginationVue name="branch_service"/>
</template>

<script>
   import EmbeddedPaginationVue from '@/components/EmbeddedPagination.vue';

   export default {
       components: {
           EmbeddedPaginationVue
       }
   }
</script>

<style>

</style>
