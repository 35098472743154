class busEvent {
    constructor() {
        this.events = {};
    }
    on(eventName, fn) {
        this.events[eventName] = this.events[eventName] || [];
        this.events[eventName].push(fn);
    }
    emit(eventName, data) {
        if (this.events[eventName]) {
            this.events[eventName].forEach(eventFn => eventFn(data));
        }
    }
}

export default new busEvent();
