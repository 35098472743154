<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <i class="bi bi-list hamburger"  @click="openSidebar"></i>
            <h1 class="navbar-brand">{{ pageTitle }}</h1>
            <div>
                <div style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb"></div>
            </div>
            <div class="info-container">
                <div class="customer-container">
                    <img src="https://picsum.photos/200/300" alt="Avatar" class="avatar">
                    <div class="customer-details" v-if="user">
                        <label class="customer">
                            {{ user.first_name }} {{ user.last_name }}
                        </label>
                        <label class="role">
                            {{ user.role || 'Admin' }}
                        </label>
                    </div>
                </div> 
            </div>
        </div>
    </nav>
</template>

<script>
    import eventBus from '@/store/eventBus';
    import * as helpers from '@/utils/helper';

    export default {
        props: ['pageTitle'],
        emits: ['toggleSidebar'],
        setup() {
            const user = helpers.userData();
            
            const openSidebar = () => {
                eventBus.emit('toggleSidebar');
            };

            return {
                user,
                openSidebar
            };
        }
    }
</script>

<style scoped>
    .navbar {
        height: 80px;
        width: 100%;
        padding: 0px;
        box-shadow: 1px 3px 1px #CECECE;
        position: absolute;
    }
    .navbar-brand {
        font-weight: 600;
        position: absolute;
        left: 260px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }
    .info-container {
        position: relative;
        background-color: #F5F5F5;
        padding: 5px 15px;
        display: flex;
    }
    .customer-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border-radius: 15px;
        word-break: break-word;
    }
    .customer {
        font-weight: bolder;
        padding: 10px 0px 0px 10px;
        margin: 0px;
    }
    .customer-details {
        display: flex;
        flex-direction: column;
    }
    .role {
        padding: 0px 0px 0px 10px;
        font-size: 12px;
        font-weight: 500;
    }
    .avatar {
        vertical-align: middle;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: 5px;
    }
    .breadcrumb {
        margin-top: 12px;
        font-size: 13px;
    }
    .active {
        font-weight: bolder;
    }

    @media (min-width: 768px) {
        .hamburger {
            display: none;
        }
    }
    @media (max-width: 767px) {
        .info-container {
            display: none;
        }
        .navbar {
            background-color: #EFEFEF!important;
        }
        .container-fluid {
            display: flex;
            flex-direction: row;
            justify-content: flex-start!important;
            gap: 20px;
            padding-left: 30px;
        }
        .hamburger::before {
            font-size: 32px;
            border: 2px solid #000000;
            border-radius: 5px;
            cursor: pointer;
        }
        .navbar-brand {
            position: static;
            margin-top: 45px;
        }
    }
</style>