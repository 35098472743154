<template>
    <div class="frame-container">
        <iframe class="frame"
            title="sir_pao_employee_schedule_report" 
            src="https://app.powerbi.com/reportEmbed?reportId=523a572b-b883-4253-807a-6b826f92ab16&autoAuth=true&ctid=de70df2e-9a73-48dc-af26-dcec8dc8a16d" 
            frameborder="0" 
            allowFullScreen="true">
        </iframe>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('auth', ['user']),
    },
}
</script>

<style scoped>
 
</style>